/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'menutech';
  src:
    url('./assets/fonts/menutech-regular.woff2') format('woff2'),
    url('./assets/fonts/menutech-regular.woff') format('woff'),
    url('./assets/fonts/menutech-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@import './app/shared/Directives/dnd-sort/style.css';
@import './assets/css/quill.snow.css';
@import './assets/css/quill.bubble.css';

html,
body {
  margin: 0;
  font-size: 16px;
  height: 100%;
  min-width: 360px;
  -webkit-overflow-scrolling: touch !important;
}

.mat-mdc-h4 {
  font-weight: 400;
}

h3 {
  font-size: 22px;
  font-weight: 300;
}

.hidden,
.hide {
  display: none !important;
}

.allergen-symbol {
  font-family: 'menutech' !important;
  font-size: 1.5em;
}

app-quill-editor .allergen-symbol {
  line-height: 1rem;
}

.allergen-symbol-clear {
  font-family: inherit;
  font-size: inherit;
}

.additives-symbol {
  display: inline-block;
  text-align: center;
  font-size: 10px;
  line-height: 16px;
  width: 17px;
  height: 17px;
  border-radius: 17px;
  position: relative;
  top: -2px;
  margin: 0 2px 0 2px;
  flex-shrink: 0;
}

button {
  font: inherit;
}

.button-primary {
  text-transform: uppercase;
  -webkit-box-shadow: 1px 2px 12px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 2px 12px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 2px 12px 1px rgba(0, 0, 0, 0.2);
}

.mat-mdc-button {
  text-transform: uppercase;
}

.mat-mdc-paginator-page-size-select {
  margin-top: 0 !important;
}

.simple-theme .mat-mdc-input-element {
  color: #333333;
}

.mat-mdc-tooltip {
  font-size: 8pt;
}

.add-entity-button {
  -webkit-box-shadow: 1px 2px 12px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 2px 12px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 2px 12px 1px rgba(0, 0, 0, 0.2);
  padding: 8px 15px !important;
  z-index: 10;
}

.add-entity-button:disabled,
.add-entity-button:disabled:hover {
  background: #eee !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 8px 15px !important;
}

.add-entity-button:disabled .mat-mdc-button-wrapper {
  color: #333333 !important;
}

.page-size .mat-mdc-select-trigger {
  width: 38px;
  min-width: auto;
}

li[draggable='false'] .drag {
  cursor: no-drop;
}

.button-primary .mat-mdc-button-wrapper,
.add-entity-button .mat-mdc-button-wrapper {
  color: #fff;
}

.mat-mdc-checkbox-layout .mat-mdc-checkbox-label {
  white-space: normal;
}

.add-entity-button .mat-mdc-button-wrapper {
  font-size: 16px;
}

login,
confirmation,
registration-container {
  display: flex;
  flex-grow: 1;
}

router-outlet + * {
  flex-grow: 1;
}

.sidenav-container:not(.logout) router-outlet + * {
  padding-bottom: 50px;
}

.ng2-pdf-viewer-container.ng2-pdf-viewer--zoom {
  overflow-x: auto !important;
}

.shadow {
  box-shadow: 1px 2px 12px 1px rgba(0, 0, 0, 0.2);
}

.select .mat-mdc-card-title {
  color: #fff;
}

.no-padding-right {
  padding-right: 0;
}

app-settings .mat-mdc-tab-label {
  min-width: 100px !important;
  width: 100%;
  height: 60px !important;
  line-height: 60px !important;
}

app-settings .mat-mdc-tab-header {
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  border-bottom: none !important;
}

app-settings .mat-mdc-tab-header .mat-mdc-tab {
  height: 56px;
}

.tab-stick .mat-mdc-tab-header {
  position: fixed !important;
  z-index: 5;
}

.mat-mdc-tab-body {
  overflow-y: hidden !important;
}

.like-radio .mat-mdc-checkbox-background,
.like-radio .mat-mdc-checkbox-frame {
  border-radius: 50px;
}

.like-radio.additives .mat-mdc-ripple > * {
  background-color: rgba(2, 154, 225, 0.2);
}

.like-radio .mat-mdc-ripple > * {
  background-color: rgba(204, 57, 100, 0.2);
}

.like-radio .mat-mdc-checkbox-checkmark {
  display: none;
}

.like-radio .mat-mdc-checkbox-background {
  width: 10px;
  height: 10px;
  margin-top: 5px;
  margin-left: 5px;
  transition:
    transform ease 280ms,
    background-color ease 280ms;
}

.options-toogle .mat-mdc-button-toggle-label-content,
.options-toogle.mat-mdc-button-toggle-appearance-standard
  .mat-mdc-button-toggle-label-content {
  line-height: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.mat-mdc-dialog-container {
  max-height: 100vh;
  height: auto !important;
}

.introjs-fixParent {
  z-index: auto !important;
  opacity: 1 !important;
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  transform: none !important;
}

.menutour-highlight,
tr.menutour-highlight td,
tr.menutour-highlight > th {
  z-index: 901 !important;
  position: relative;
}

.ql-container {
  height: calc(100% - 39px);
}

.more-similar-options {
  margin-top: 25px;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  text-transform: uppercase;
  padding: 7px 18px;
  border: none;
  border-radius: 3px;
  box-shadow: none;
}

@media only screen and (max-width: 768px) {
  .tab-stick .mat-mdc-tab-header {
    position: relative !important;
    z-index: 11;
  }

  .add-entity-button {
    min-width: 70px !important;
  }

  .mat-mdc-progress-bar.mat-mdc-accent .mat-mdc-progress-bar-fill::after {
    background-color: #fff !important;
  }

  .mat-mdc-tab-label {
    line-height: 60px !important;
    height: 60px !important;
  }

  .mat-mdc-tab-header {
    position: relative !important;
    border-bottom: none !important;
    background: #fff;
    -webkit-box-shadow: 3px 2px 5px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 3px 2px 5px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 3px 2px 5px 1px rgba(0, 0, 0, 0.2);
  }

  app-settings .mat-mdc-tab-label {
    line-height: 60px !important;
    height: 60px !important;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }

  .mat-mdc-progress-bar.mat-mdc-accent .mat-mdc-progress-bar-buffer {
    background-color: #bbdefb;
  }
}

.is-safari {
  cursor: pointer;
}

a,
a:hover {
  cursor: pointer;
}

hr {
  border-width: 0 0 1px;
  border-color: #dbdbdb;
}

.italic {
  font-style: italic !important;
}

.auth-bar .links a {
  font-size: 14px;
  line-height: 18px;
  color: #757575;
  display: block;
  text-decoration: underline;
}

.auth-page a.register-link {
  margin-top: 10px;
  font-weight: 500;
}

.auth-page {
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
  background-color: white;
  width: 100%;
  min-height: 100%;
}

.auth-page h1.title {
  padding-top: 20px;
  text-align: center;
  text-transform: uppercase;
}

.auth-bar {
  margin: 0 auto;
  background-color: #fff;
  padding: 30px;
  width: 300px;
  -webkit-box-shadow: 7px 7px 26px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 7px 7px 26px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 7px 7px 26px 2px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  align-self: flex-end;
}

@media only screen and (max-width: 768px) {
  .auth-bar {
    margin: 100px auto 45px;
  }
}

.auth-bar h1.auth-title,
.auth-title {
  padding: 15px 0 25px 0;
  text-align: center;
  margin: 0;
}

.noscroll {
  position: fixed !important;
  width: 100%;
  overflow: hidden !important;
}

.extend-height {
  padding-bottom: 474px;
}

.template-option .mat-mdc-form-field-infix {
  display: flex;
  width: auto;
}

.ql-container.ql-snow {
  border: none;
}

.ql .ql-container.ql-snow {
  box-shadow: 0 0 25px 10px orange;
}

.buttons-menu {
  padding: 20px !important;
}

.create-menu-item {
  display: flex !important;
  align-items: center;
}

.create-menu-item .icons {
  color: rgb(172, 172, 172);
  font-size: 21px;
}

.create-menu-item .start {
  padding-left: 0;
  padding-right: 16px;
}

.create-menu-item .icons.end {
  margin-left: auto;
  padding-right: 0;
  margin-left: auto;
  float: right;
}

.create-menu-text {
  padding: 16px;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.1;
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.08) !important;
}

.create-menu {
  border-radius: 0 !important;
}

.write-new-menu-nav-opened {
  max-height: calc(100vh - 66px);
  overflow: auto;
}

@media only screen and (min-width: 768px) {
  .write-new-menu-nav-opened {
    position: fixed;
    left: 240px;
    top: 64px;
  }

  .write-new-menu-nav-closed {
    position: fixed;
    left: 64px;
  }
}

.light-text {
  font-weight: lighter !important;
}

.slidedown {
  padding: 0;
}

ingredients tag:not(.readonly):not(.tag--editing):not(.tag__selected):active {
  background: white;
  box-shadow: none;
}

ingredients
  tag:not(:focus):not(.tag--editing):not(:active):not(.readonly):hover {
  background: white;
  box-shadow: none;
}

ingredients tag:not(.readonly):not(.tag--editing):not(.tag__selected):focus {
  background: white;
  box-shadow: none;
}

ingredients
  tag:not(.readonly):not(.tag--editing):not(.tag__selected):focus
  path {
  fill: #444;
}

ingredients
  tag:not(.readonly):not(.tag--editing):not(.tag__selected):active
  path {
  fill: #444;
}

.semi-bold {
  font-weight: 450 !important;
}

.color-picker {
  z-index: 5000 !important;
  border: 0 !important;
  border-radius: 0 0 4px 4px;
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  .ok-button {
    font-size: 14px;
  }

  .ok-button:hover {
    background: rgba(0, 0, 0, 0.12);
  }

  .cancel-button {
    font-size: 13px;
  }

  .cancel-button:hover {
    background: rgba(0, 0, 0, 0.12);
  }

  .arrow.arrow-bottom-left,
  .arrow.arrow-left,
  .arrow.arrow-left-bottom {
    border-left-color: rgba(0, 0, 0, 0.54) !important;
    top: 7px !important;
    right: -20px !important;
  }

  .button-area {
    padding: 0 10px 10px;
  }
}

.plus-btns-menu.mat-mdc-menu-panel {
  position: fixed;
}

section-item .input,
.numbering-block .input,
dish-item .input {
  line-height: normal;
  margin-bottom: -5px;
}

choose-plan-dialog .mat-horizontal-content-container {
  padding-bottom: 0;
}

.invitation-tooltip,
.invitation-btn-tooltip {
  text-transform: none;
  font-size: 12px;
  background-color: rgb(71, 71, 71);
  max-width: 350px !important;
}

.invitation-tooltip {
  width: 344px;
}

.top-navbar-tooltip {
  --mdc-plain-tooltip-container-color: #efefef;
  --mdc-plain-tooltip-supporting-text-color: #000;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-container-shape: 0;
  --mdc-plain-tooltip-supporting-text-size: 14px;

  .mdc-tooltip__surface {
    box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.4);
  }
}

.top-navbar-tooltip.send-dialog {
  position: relative;
  top: 7px;
  font-size: 15px;
  padding: 7px 10px;
  text-transform: inherit;
}

.registration-step-tooltip {
  background: #fcfeff;
  border-radius: 0 !important;
  border: 1px solid #e8f4fc;
  color: #333 !important;
  text-transform: initial;
  font-size: 14px;
  text-align: center;
  margin: 0 !important;
  margin-top: 3px !important;
  box-shadow: 0 2px 13px rgba(0, 0, 0, 0.3);
  overflow: visible !important;
  position: relative;
  bottom: 8px;
  max-width: 300px !important;
  width: 300px;
  padding: 12px 8px;
}

.registration-step-tooltip::before {
  content: '';
  position: absolute;
  display: block;
  width: 5px;
  height: 5px;
  bottom: -15px;
  left: calc(50% - 11px);
  border-top: 15px solid #fcfeff;
  border-left: transparent 8px solid;
  border-right: transparent 8px solid;
}

.invitation-btn-tooltip {
  width: auto;
}

.template-disabled {
  opacity: 0.5;
}

.button-spinner .button-text {
  display: flex;
}

.button-spinner .inner-spinner {
  margin-left: 5px;
}

.help-menu-panel {
  max-width: 297px !important;
  width: 297px;
  border-radius: 0 !important;
  position: relative;
  right: -85px;
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14);
}

.help-menu-panel.logout-panel {
  right: 0;
}

.help-menu-panel .mat-mdc-menu-content:not(:empty) {
  padding: 0;
}

.send-message-panel {
  max-width: 100vw !important;
}

.send-message-panel .mat-mdc-dialog-container {
  border-radius: 0 !important;
}

ngx-mat-timepicker-dialog .timepicker-header {
  height: auto;
}

font-picker .font-picker {
  border: 0;
  border-radius: 4px;
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  .search-box {
    margin-top: -20px;
  }

  .search-box input {
    color: rgba(0, 0, 0, 0.87);
  }

  input:active,
  input:focus,
  input:hover,
  select:active,
  select:focus,
  select:hover {
    border-bottom: 1px solid #d8d8d8 !important;
  }

  .search-box input::placeholder {
    color: #999;
  }

  .arrow.arrow-bottom {
    top: -20px;
    left: 10px;
    border-width: 10px;
    border-color: transparent transparent #d8d8d8;
  }

  .arrow.arrow-bottom:after {
    top: -8px;
    left: -9px;
    border-width: 9px;
  }

  .font-list .font-group .group-text.black {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: 'Dubai';
    font-size: 14px;
    font-weight: 400;
  }

  .font-list .font-group .group-line.black {
    color: rgba(0, 0, 0, 0.87) !important;
    height: 1px;
    margin-top: 19px;
  }

  .font-list .font-item:hover {
    background: #eee;
  }

  .search-field {
    padding: 0;
    padding-top: 22px;
    padding-left: 10px;
    height: 55px;
  }

  .search-reset {
    top: 22px;
    font-size: 20px;
    font-weight: 300;
  }
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.mat-mdc-button[disabled] {
  background: lightgray !important;
  pointer-events: none;
  cursor: not-allowed;
}

.g-top-nav {
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  align-self: flex-start;
}

ngx-mat-select-search .mat-mdc-select-search-inner {
  height: 3em;
  display: flex;
  align-items: center;

  .mat-mdc-input-element {
    height: 25px !important;
  }
}

.mt-tooltip-bottom .arrow,
.mt-tooltip-bottom-html .arrow {
  content: '';
  position: absolute;
  display: block;
  width: 5px;
  height: 5px;
  top: -9px;
  left: calc(50% - 9px);
  border-bottom: 9px solid #efefef;
  border-left: transparent 9px solid;
  border-right: transparent 9px solid;
  box-sizing: border-box;
}

.mt-tooltip-top .arrow,
.mt-tooltip-top-html .arrow {
  content: '';
  position: absolute;
  display: block;
  width: 5px;
  height: 5px;
  bottom: -9px;
  left: calc(50% - 9px);
  border-top: 9px solid #efefef;
  border-left: transparent 9px solid;
  border-right: transparent 9px solid;
  box-sizing: border-box;
}

.mt-tooltip-after .arrow,
.mt-tooltip-after-html .arrow {
  content: '';
  position: absolute;
  display: block;
  width: 5px;
  height: 5px;
  top: calc(50% - 8px);
  left: -8px;
  border-right: 8px solid #efefef;
  border-top: transparent 8px solid;
  border-bottom: transparent 8px solid;
  box-sizing: border-box;
}

.mt-tooltip-bottom-html .arrow,
.mt-tooltip-top-html .arrow,
.mt-tooltip-after-html .arrow {
  border-bottom-color: white;
}

.delete-style-menu {
  border-radius: 0 !important;
}

.sidepanel-go-back .go-back {
  width: 100%;
  text-transform: initial;
  font-weight: 400;
  padding-left: 5px;
  text-align: start;
  height: 55px;
  border-bottom: 2px solid lightgray;
  border-radius: 0;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */

@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */

@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

input {
  font-family: 'Dubai', 'Helvetica Neue', Helvetica, sans-serif;
}

.stripe-card-base-class {
  // iconColor: "#009fed",
  color: #212121;
  line-height: 20px;
  font-weight: 300;
  font-family: 'Dubai', 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 18px;
}

.tooltip-icon {
  padding: 0;
  color: #ccc;
}

.upload-single-image-label {
  display: flex !important;
  align-items: center;
  justify-content: center;
  outline-offset: -1px !important;
  margin-left: 0 !important;
  padding: 0 !important;
  left: 0;
}

.upload-single-image-label {
  file-upload-placeholder,
  .upload-input {
    position: static !important;
    top: 0 !important;
    right: 0 !important;
    transform: none !important;
    transition: color 0.2s;
  }
}

.simple.select .mat-mdc-form-field-wrapper {
  padding-bottom: 0;
}

.simple.select .mat-mdc-form-field-underline {
  opacity: 0;
}

.simple.mat-mdc-form-field.mat-mdc-form-field-appearance-fill,
.simple .mat-mdc-select,
.simple .mat-mdc-form-field {
  min-width: auto;
}

.mat-mdc-select.ng-invalid.ng-touched:not(.mat-mdc-select-disabled)
  .mat-mdc-select-trigger {
  color: #f44336 !important;
}

.mat-warn {
  color: var(--app-warn-500);
}

.mat-mdc-form-field.mat-mdc-form-field-appearance-fill:not(
    .mat-mdc-form-field-type-mat-date-range-input
  )
  input.mat-mdc-input-element {
  // height: 1.125em; -> this breaks the scrolling-down in Firefox
  margin-bottom: -0.3em;
}

.mat-mdc-form-field-type-mat-select.mat-mdc-form-field-appearance-fill
  .mat-mdc-form-field-infix {
  padding-top: 0.48em;
}

.mat-mdc-form-field.mat-mdc-form-field-appearance-fill
  .mat-mdc-form-field-flex {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
}

.mat-mdc-form-field.mat-mdc-form-field-appearance-fill
  .mat-mdc-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.08) !important;
}

.mat-mdc-form-field.mat-mdc-form-field-appearance-fill
  .mat-mdc-select-arrow-wrapper {
  transform: translateY(-0.05em);
}

.mat-mdc-form-field.mat-mdc-form-field-appearance-fill
  .mat-mdc-form-field-suffix
  .mat-icon-button
  .mat-icon {
  font-size: 18px;
}

.mat-mdc-form-field.mat-mdc-form-field-appearance-fill
  .mat-mdc-form-field-prefix
  .mat-mdc-datepicker-toggle-default-icon,
.mat-mdc-form-field.mat-mdc-form-field-appearance-fill
  .mat-mdc-form-field-suffix
  .mat-mdc-datepicker-toggle-default-icon {
  width: 1em !important;
  height: 1em !important;
}

.mat-mdc-form-field.mat-mdc-form-field-appearance-fill
  .mat-mdc-form-field-subscript-wrapper {
  padding: 0;
}

mat-form-field.mat-mdc-form-field.mat-mdc-form-field-appearance-fill
  .mat-mdc-form-field-suffix
  .mat-icon-button {
  width: 1.75em;
  height: 1.75em;
}

// Date-range input fixes
.mat-mdc-form-field.mat-mdc-form-field-appearance-fill
  .mat-mdc-date-range-input-container {
  position: relative;
  top: 2px;
}

.mat-mdc-form-field.mat-mdc-form-field-appearance-fill.mat-mdc-form-field-type-mat-date-range-input
  .mat-mdc-form-field-suffix {
  top: 6px;
}

.mat-mdc-form-field
  .mat-mdc-date-range-input-start-wrapper
  .mat-mdc-date-range-input-mirror,
.mat-mdc-form-field
  .mat-mdc-date-range-input-start-wrapper
  .mat-mdc-date-range-input-inner,
.mat-mdc-form-field .mat-mdc-date-range-input-end-wrapper,
.mat-mdc-form-field .mat-mdc-date-range-input-separator {
  margin-top: 0;
  height: 24px;
  line-height: 24px;
}

.mat-mdc-form-field .mat-mdc-date-range-input-separator {
  line-height: 24px;
}

.overwritten-info {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: var(--app-primary-500);
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 7px;
  margin-left: -10px;
}

.icons-wrapper {
  display: flex;
}

// Tour element card header
.mat-mdc-card-header .header-group .mat-mdc-card-title {
  font-weight: 700;
  font-size: 1.25em;
  line-height: 1.35em;
  white-space: normal !important;
}

// Ensure that color picker overlay is not cut off
.mat-expansion-panel.mat-expanded {
  overflow: visible !important;
}

@media only screen and (max-width: 768px) {
  .auth-page {
    padding: 0 5px;
  }
}

@media only screen and (max-width: 839px) {
  .choose-payment .mat-mdc-dialog-container {
    background: #efefef;
    padding: 15px;
  }

  .button-stick {
    position: relative !important;
    z-index: 9;
  }

  .choose-payment {
    width: 100%;
    height: 100%;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 375px) {
  .g-top-nav {
    padding: 25px 10px;
  }
}
